<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        资讯列表
      </template>
      <template #input>
        <a-input placeholder="请输入标题名称" v-model="articleTitle" allow-clear width="240" />
        <a-button type="primary" class="all_boder_btn" style="margin-right: 20px;" @click="getData('')">搜索</a-button>
        <a-button class="all_boder_btn" @click="goDetail('')">新建</a-button>
      </template>
    </HeaderBox>
    <div class="content">
      <!-- 栏目菜单 -->
      <a-tree
        @select="seleteMenv" 
        @expand="onExpand" 
        :expanded-keys="expandedKeys"
        :selected-keys="[selectedKey]"
        :load-data="onLoadData" 
        :tree-data="treeData" />

      <!-- 资讯列表 -->
      <a-table
        class="table-right"
        :rowKey="item => item.id"
        :loading="loading"
        :columns="columns"
        :data-source="tableData"
        @change="changePage"
        :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: (res) => `共${total}条`,
        }"
      >
        <template slot="index" slot-scope="item, row, index">
          {{ (pageNumber - 1) * pageSize + index + 1 }}
        </template>

        <template slot="createTime" slot-scope="item">
          {{ item.split(" ")[0] }}
        </template>

        <template slot="sort" slot-scope="item,row">
          <a-input-number id="inputNumber" v-model="row.sort" :min="1" @change="onSortChange(row)" />
        </template>

        <template slot="isEnable" slot-scope="item">
          <span :style="{color: item == 1?'green':'red'}">{{ item == 1?'已上架':'未上架' }}</span>
        </template>
        
        <template slot="operation" slot-scope="item, row">
          <span class="btn_router_link">
            <a @click="goDetail(row)">编辑</a>
            <span>|</span>
            <a @click="editState(row)">{{ row.isEnable == 1?'下架':'上架' }}</a>
            <span>|</span>
            <a @click="deteData(row)">删除</a>
          </span>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
{
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "标题",
    width: '350px',
    align: "center",
    ellipsis :'true',
    dataIndex: "articleTitle",
  },
  {
    title: "点击量",
    align: "center",
    dataIndex: "pageviews",
  },
  {
    title: "发布时间",
    align: "center",
    dataIndex: "publishTime"
  },
  {
    title: "排序",
    align: "center",
    dataIndex: "sort",
    scopedSlots: {
      customRender: "sort",
    },
  },
  {
    title: "状态",
    align: "center",
    ellipsis :'true',
    dataIndex: "isEnable",
    scopedSlots: { customRender: "isEnable" },
  },
  {
    title: "操作",
    align: "center",
    width: '170px',
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];
export default {
  components: { HeaderBox },
  data() {
    return {
      // 表格相关
      columns,
      articleTitle: '',
      tableData: [],
      total: 0,
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      loading: false,

      // 栏目菜单相关
      columnId: '',
      treeData: [],

      expandedKeys: [],
      selectedKey: null,
    };
  },
  created() {
    this.getList(0)
  },
  mounted(){
    this.loadSelectedKey();
    this.loadExpandedKeys();
  },
  methods: {
    // 修改位置
    onSortChange(item) {
      if(this.timer) {
        clearTimeout(this.timer)
      }
      let _this = this;
      this.timer = setTimeout(()=>{
        this.timer = null;
        _this
          .$ajax({
            url: "/hxclass-management/Article/update",
            method: "post",
            params: item
          })
          .then((res) => {
            if (res.code == 200 && res.success) {
              _this.$message.success("操作成功");
              _this.getData();
            }
          });
      },600)
    },

    // 跳转详情
    goDetail(e) {
      if(!this.columnId && !e) {
        return this.$message.warning('请先选择栏目选项！')
      }
      let code = '';
      this.treeData.some(item=>{
        if(this.columnId && item.key == this.columnId) {
          code = item.columnCode;
          return true
        }else if(e.columnId && item.key == e.columnId) {
          code = item.columnCode;
          return true
        }
      })
      let query = {};
      query.code = code;
      if(e) {
        query.typeId = e.columnId
        query.id = e.id
      }else{
        query.typeId = this.columnId
      }
      this.$router.push({path: '/admin/ContentConfig/informationAdd',query})
    },

    // 分页
    changePage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getData();
    },

    // 资讯列表
    getData() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/Article/list",
        method: "GET",
        params: {
          articleTitle: this.articleTitle,
          columnId: this.columnId,
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
        this.loading = false;
      });
    },

    // 删除
    deteData(e) {
      let _this = this;
      this.$confirm({
        title: "确定删除该条数据吗?",
        okText: "确认",
        onOk() {
          _this.visible = false;
          _this
            .$ajax({
              url: "/hxclass-management/Article/delete/" + e.id,
              method: "DELETE",
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success("删除成功");
                _this.visible = false;
                _this.getData();
              }
            });
        },
        onCancel() {},
      });
    },

    // 上下架
    editState(item) {
      let _this = this;
      this.$confirm({
        title: `确定${item.isEnable == 1?'下架':'上架'}该条数据吗?`,
        okText: "确认",
        onOk() {
          item.isEnable = item.isEnable == 1?0:1;
          _this
            .$ajax({
              url: "/hxclass-management/Article/update",
              method: "post",
              params: item
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success("操作成功");
                _this.visible = false;
                _this.getData();
              }
            });
        },
        onCancel() {},
      });
    },

    onExpand(expandedKeys) {
      localStorage.setItem('expandedTreeKeys', JSON.stringify(expandedKeys));
      this.expandedKeys = expandedKeys;
    },
    loadExpandedKeys() {
      const savedKeys = localStorage.getItem('expandedTreeKeys');
      if (savedKeys) {
        this.expandedKeys = JSON.parse(savedKeys);
      }
    },

    loadSelectedKey() {
      const savedKey = localStorage.getItem('selectedTreeKey');
      if (savedKey) {
        this.selectedKey = Number(savedKey);
        this.columnId = Number(savedKey);
      }
      this.getData()
    },

    // 切换菜单
    seleteMenv(keys) {
      if(!keys.length) return;
      // 存储已选节点
      this.selectedKey = keys[0];
      localStorage.setItem('selectedTreeKey', this.selectedKey);

      this.columnId = keys[0];
      this.getData()
    },

    onLoadData(treeNode) {
      return new Promise(resolve => {
        if (treeNode.dataRef.children) {
          resolve();
          return;
        }
        let _this = this
        this.getList(treeNode.dataRef.key,treeNode.dataRef).then(res=>{
          _this.treeData = [..._this.treeData];
          resolve();
        })
      });
    },
    async getList(id,obj) {
      await this.$ajax({
        url: '/hxclass-management/ArticleColumn/list/' + id
      }).then(res=>{
        if(res.code == 200 && res.success){
          let arr = res.data.map(item=>{
            return {
              title: item.columnTitle,
              key: item.id,
              isLeaf: item.subset == 0,
              columnCode: item.columnCode
            }
          })
          if(id == 0) {
            this.treeData = arr;
          }else{
            obj.children = arr;
          }
        }
      }).catch(err=>{
        
      })
    }
  },
}
</script>

<style scoped lang="less">
  .content{
    display: flex;
    /deep/.ant-tree{
      width: 200px;
    }
    /deep/.ant-table-wrapper{
      flex: 1;
    }
  }
</style>